export class Message {
  public folio: string;
  public from: string;
  public text: string;
  public to: string;

  constructor() {
    this.folio = null;
    this.from = null;
    this.text = null;
    this.to = null;
  }
}
