import { Interfaceble } from '../shared/interfaces/interfaceble';
import { InventoryDetail } from './inventory-detail';
import { Location } from '../catalog/location/location';
import { Lpn } from '../lpn/lpn';

import * as _ from 'lodash';

export class InventoryHeader implements Interfaceble {
  public id: number;
  public buildingId: number;
  public creationDate: Date;
  public equipmentId: number;
  public inventoryDetails: InventoryDetail[];
  public inventoryDetail: InventoryDetail;
  public lastChange: Date;
  public location: Location;
  public lpn: Lpn;
  public uomId: number;
  public volume: number;
  public weight: number;
  public locationId: number;
  public accountId: number;
  public warehouseId: number;

  constructor() {
    this.id = null;
    this.buildingId = null;
    this.creationDate = new Date();
    this.equipmentId = null;
    this.inventoryDetails = [];
    this.location = new Location();
    this.lpn = new Lpn();
    this.uomId = null;
    this.volume = 0.0;
    this.weight = 0.0;
  }

  public toInterface() {
    return {
      id: this.id,
      buildingId: this.buildingId,
      weight: this.weight,
      volume: this.volume,
      location: this.location.toInterface(),
      lpn: this.lpn.toInterface(),
      inventoryDetails: this.detailsToInterface(this.inventoryDetails)
    };
  }

  private detailsToInterface(inventoryDetails: InventoryDetail[]) {
    let inventoryDetailsInterface: Object[] = [];
    _.forEach(inventoryDetails, (inventoryDetail: InventoryDetail) => {
      inventoryDetailsInterface.push(inventoryDetail.toInterface());
    });
    return inventoryDetailsInterface;
  }
}
