import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { registerLocaleData, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ScfCommunicationService, ScfToastrModule, ScfSidebarModule, ScfHeaderModule } from 'scf-library';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import localeMx from '@angular/common/locales/es-MX';

import { APP_ROUTES } from './app.routes';
import { AppComponent } from './app.component';
import { AuthModule } from './shared/authentication/modules/auth.module';
import { CONSTANTS } from './shared/constants';
import { ENVIRONMENT } from '../environments/environment';
import { PagesComponent } from './pages.component';
import { SidebarWrapperModule } from './shared/mobile-sidebar/sidebar-wrapper.module';
import { SystemLabelsResolver } from './shared/system-labels/system-labels-resolver.service';
import { UtilsModule } from './utils/utils.module';

registerLocaleData(localeMx, 'es-Mx');

import { AppInsightsGlobalErrorHandler } from './shared/monitoring/appInsights/app-insights-global-error-handler';
import { AppInsightsMonitoringService } from './shared/monitoring/appInsights/app-insights-monitoring.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatIconModule } from '@angular/material/icon';

const config: SocketIoConfig = { 
  url: `${ENVIRONMENT.SOCKET_URI}/${ENVIRONMENT.SOCKET_WEP_CORE_API_MICROSERVICE_ID}/${ENVIRONMENT.TENANT_ID}`,
  options: {
    transports: [CONSTANTS.WEBSOCKET]
  }
};

@NgModule({
  declarations: [AppComponent, PagesComponent],
  imports: [AuthModule, BrowserModule, BrowserAnimationsModule,
    NgbModule, APP_ROUTES, SidebarWrapperModule, UtilsModule, FontAwesomeModule,
    SocketIoModule.forRoot(config),
    ScfSidebarModule, ScfHeaderModule, MatIconModule, ScfToastrModule],
  providers: [SystemLabelsResolver, { provide: LocationStrategy, useClass: HashLocationStrategy },
     { provide: LOCALE_ID, useValue: 'es-MX' },
    ScfCommunicationService, AppInsightsMonitoringService,
  { provide: ErrorHandler, useClass: AppInsightsGlobalErrorHandler }],
  bootstrap: [AppComponent]
})
export class AppModule { }
