export class Accessory {
  id: number;
  name: string;
  description: string;
  maximumVolume: number;
  isActive: boolean;
  lastChange: Date;
  userId: number;

  constructor() {
    this.id = undefined;
    this.name = '';
    this.description = '';
    this.maximumVolume = 0;
    this.isActive = false;
    this.userId = undefined;
  }
}
