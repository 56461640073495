import { ProgressionInventoryStatus } from './progression-inventory-status';

export class Progression {
  id: number;
  name: string;
  description: string;
  progressionInventoryStatuses: ProgressionInventoryStatus[];

  constructor() {
    this.id = null;
    this.name = '';
    this.description = '';
    this.progressionInventoryStatuses = [];
  }
}
