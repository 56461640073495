import { Footprint } from '../catalog/footprint/footprint';
import { FootprintDetail } from '../catalog/footprint/footprint-detail';
import { Interfaceble } from '../shared/interfaces/interfaceble';
import { InventoryHeader } from './inventory-header';
import { InventoryStatus } from '../catalog/inventory-status/inventory-status';
import { Product } from '../catalog/product/product';

export class InventoryDetail implements Interfaceble {
  public admissionDate: any;
  public caseId: number;
  public confirmedSerials: string[];
  public customs: string;
  public dynamicAttribute1: string;
  public dynamicAttribute2: string;
  public dynamicAttribute3: string;
  public expirationDate: Date;
  public expirationDateString: string;
  public footprint: Footprint;
  public footprintDetail: FootprintDetail;
  public footprintId: number;
  public id: number;
  public importDate: Date;
  public importNumber: string;
  public inventoryHeader: InventoryHeader;
  public inventoryHeaderId: number;
  public inventoryStatus: InventoryStatus;
  public inventoryStatusName: string;
  public isProcessed: boolean;
  public lot: string;
  public partId: number;
  public product: Product;
  public productId: number;
  public quantity: number;
  public serial: string;
  public timeExpirationDate: Date;

  constructor() {
    this.admissionDate = new Date();
    this.caseId = null;
    this.customs = null;
    this.confirmedSerials = [];
    this.dynamicAttribute1 = null;
    this.dynamicAttribute2 = null;
    this.dynamicAttribute3 = null;
    this.expirationDate = new Date();
    this.footprintId = null;
    this.id = null;
    this.importDate = null;
    this.importNumber = null;
    this.inventoryHeaderId = null;
    this.inventoryStatus = new InventoryStatus();
    this.lot = null;
    this.partId = null;
    this.productId = null;
    this.quantity = null;
    this.serial = null;
    // TODO Se coloca propiedad product para despues quitar productId
    this.footprintDetail = new FootprintDetail();
    this.inventoryHeader = new InventoryHeader();
    this.product = new Product();
    this.footprint = new Footprint();
    this.inventoryStatusName = null;
    this.timeExpirationDate = null;
    this.expirationDateString = null;
  }

  public toInterface() {
    return {
      admissionDate: this.admissionDate,
      caseId: this.caseId,
      customs: this.customs,
      confirmedSerials: this.confirmedSerials,
      dynamicAttribute1: this.dynamicAttribute1,
      dynamicAttribute2: this.dynamicAttribute2,
      dynamicAttribute3: this.dynamicAttribute3,
      expirationDate: this.expirationDate,
      footprintId: this.footprintId,
      id: this.id,
      importDate: this.importDate,
      importNumber: this.importNumber,
      inventoryHeaderId: this.inventoryHeaderId,
      inventoryStatus: this.inventoryStatus.name,
      lot: this.lot,
      partId: this.partId,
      productId: this.productId,
      quantity: this.quantity,
      serial: this.serial,
      footprintDetail: this.footprintDetail,
      inventoryHeader: this.inventoryHeader,
      product: this.product.sku,
      footprint: this.footprint.name
    };
  }
}
