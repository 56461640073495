import { Accessory } from '../accessory/accessory';
import { Account } from '../account/account';
import { FootprintDetail } from './footprint-detail';
import { Product } from '../product/product';

export class Footprint {
  id: number;
  name: string;
  description: string;
  isDefault: boolean;
  product: Product;
  accessory: Accessory;
  footprintDetails: FootprintDetail[];
  isActive: boolean;
  lastChange: Date;
  userId: number;
  layerBoxes: number;
  layers: number;
  account: Account;
  maxQuantityPerPallet: number;

  constructor() {
    this.accessory = new Accessory();
    this.account = new Account();
    this.description = '';
    this.id = undefined;
    this.isActive = false;
    this.isDefault = false;
    this.lastChange = new Date();
    this.layerBoxes = undefined;
    this.layers = undefined;
    this.name = '';
    this.product = new Product();
    this.userId = undefined;
    this.maxQuantityPerPallet = undefined;
  }
}
