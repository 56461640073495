import { FootprintDetail } from '../../../catalog/footprint/footprint-detail';
import { Lpn } from '../../../lpn/lpn';
import { Session } from '../../../session/session';
import { ShipmentOrderDetail } from '../../picking/shipment-order/shipment-order-detail';

class OriginHeader {
  private _id: number;
  private _locationId: number;
  private _lpn: Lpn;

  constructor() {
    this._lpn = new Lpn();
  }

  get id(): number {
    return this._id;
  }

  set id(id: number) {
    this._id = id;
  }

  get locationId(): number {
    return this._locationId;
  }

  set locationId(locationId: number) {
    this._locationId = locationId;
  }

  get lpn(): Lpn {
    return this._lpn;
  }

  set lpn(lpn: Lpn) {
    this._lpn = lpn;
  }

  public toJSON(): Object {
    return {
      id: this.id,
      locationId: this.locationId,
      lpn: this.lpn.toJSON()
    };
  }
}

class DestinationHeader {
  private _locationId: number;
  private _lpn: Lpn;

  constructor() {
    this._lpn = new Lpn();
  }

  get locationId(): number {
    return this._locationId;
  }

  set locationId(locationId: number) {
    this._locationId = locationId;
  }

  get lpn(): Lpn {
    return this._lpn;
  }

  set lpn(lpn: Lpn) {
    this._lpn = lpn;
  }

  public toJSON(): Object {
    return {
      locationId: this.locationId,
      lpn: this.lpn.toJSON()
    };
  }
}

export class PickingRf {
  private _pickingId: number;
  private _productId: number;
  private _confirmedSerials: string[];
  private _quantity: number;
  private _lot: string;
  private _serial: string;
  private _dynamicAttribute1: string;
  private _dynamicAttribute2: string;
  private _dynamicAttribute3: string;
  private _pickingReasonId: number;
  private _footprintDetail: FootprintDetail;
  private _inventoryStatusId: number;
  private _originHeader: OriginHeader;
  private _destinationHeader: DestinationHeader;
  private _session: Session;
  private _shipmentOrderDetail: ShipmentOrderDetail;

  constructor() {
    this._footprintDetail = new FootprintDetail();
    this._originHeader = new OriginHeader();
    this._destinationHeader = new DestinationHeader();
    this._session = new Session();
    this._shipmentOrderDetail = new ShipmentOrderDetail();
    this._confirmedSerials = [];
  }

  get pickingId(): number {
    return this._pickingId;
  }

  set pickingId(pickingId: number) {
    this._pickingId = pickingId;
  }

  get quantity(): number {
    return this._quantity;
  }

  set quantity(quantity: number) {
    this._quantity = quantity;
  }

  get lot(): string {
    return this._lot;
  }

  set lot(lot: string) {
    this._lot = lot;
  }

  get serial(): string {
    return this._serial;
  }

  set serial(serial: string) {
    this._serial = serial;
  }

  get confirmedSerials(): string[] {
    return this._confirmedSerials;
  }

  set confirmedSerials(serials: string[]) {
    this._confirmedSerials = serials;
  }

  get dynamicAttribute1(): string {
    return this._dynamicAttribute1;
  }

  set dynamicAttribute1(dynamicAttribute1: string) {
    this._dynamicAttribute1 = dynamicAttribute1;
  }

  get dynamicAttribute2(): string {
    return this._dynamicAttribute2;
  }

  set dynamicAttribute2(dynamicAttribute2: string) {
    this._dynamicAttribute2 = dynamicAttribute2;
  }

  get dynamicAttribute3(): string {
    return this._dynamicAttribute3;
  }

  set dynamicAttribute3(dynamicAttribute3: string) {
    this._dynamicAttribute3 = dynamicAttribute3;
  }

  get pickingReasonId(): number {
    return this._pickingReasonId;
  }

  set pickingReasonId(pickingReasonId: number) {
    this._pickingReasonId = pickingReasonId;
  }

  get productId(): number {
    return this._productId;
  }

  set productId(productId: number) {
    this._productId = productId;
  }

  get inventoryStatusId(): number {
    return this._inventoryStatusId;
  }

  set inventoryStatusId(inventoryStatusId: number) {
    this._inventoryStatusId = inventoryStatusId;
  }

  get footprintId(): number {
    return this._footprintDetail.footprintId;
  }

  get footprintDetail(): FootprintDetail {
    return this._footprintDetail;
  }

  set footprintDetail(footprintDetail: FootprintDetail) {
    this._footprintDetail = footprintDetail;
  }

  get originHeader(): OriginHeader {
    return this._originHeader;
  }

  set originHeader(originHeader: OriginHeader) {
    this._originHeader = originHeader;
  }

  get destinationHeader(): DestinationHeader {
    return this._destinationHeader;
  }

  set destinationHeader(destinationHeader: DestinationHeader) {
    this._destinationHeader = destinationHeader;
  }

  get shipmentOrderDetail(): ShipmentOrderDetail {
    return this._shipmentOrderDetail;
  }

  set shipmentOrderDetail(shipmentOrderDetail: ShipmentOrderDetail) {
    this._shipmentOrderDetail = shipmentOrderDetail;
  }

  get session(): Session {
    return this._session;
  }

  set session(session: Session) {
    this._session = session;
  }

  public toJSON(): Object {
    return {
      pickingId: this.pickingId,
      productId: this.productId,
      quantity: this.quantity,
      lot: this.lot,
      serial: this.serial,
      confirmedSerials: this.confirmedSerials,
      dynamicAttribute1: this.dynamicAttribute1,
      dynamicAttribute2: this.dynamicAttribute2,
      dynamicAttribute3: this.dynamicAttribute3,
      pickingReasonId: this.pickingReasonId,
      footprintId: this.footprintId,
      footprintDetail: this.footprintDetail,
      inventoryStatusId: this.inventoryStatusId,
      originHeader: this.originHeader.toJSON(),
      destinationHeader: this.destinationHeader.toJSON(),
      session: this.session,
      shipmentOrderDetail: this.shipmentOrderDetail
    };
  }
}
