import { distinctUntilChanged } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { isEqual as _isEqual, isNil as _isNil, toString as _toString } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { Socket } from 'ngx-socket-io';

import { CONSTANTS } from '../../../shared/constants';
import { ENVIRONMENT } from '../../../../environments/environment';
import { SocketChannels } from './socket-channels';
import { SocketEvents } from './socket-events';
import { SocketPayload } from './models/socket-payload';
import { SocketSession } from './models/socket-session';

@Injectable({ providedIn: 'root' })
export class SocketService {
  private static readonly DEFAULT_SOCKET_MICROSERVICE_ID = 'WEP_CORE_API';
  // remove when tenants are supported
  private static readonly DEFAULT_TENANT_ID = 'sa';
  private subscribedChannels: Set<string>;
  private currentClientId?: string;
  private socketSession: Subject<SocketSession>;
  private lastSocketSession: SocketSession;

  constructor(private socket: Socket) {
    this.subscribedChannels = new Set();
    this.socketSession = new Subject<SocketSession>();
    this.lastSocketSession = new SocketSession();
    this.socketSession.pipe(
      distinctUntilChanged((prev, curr)=> {
        return  _isEqual(prev.socketId, curr.socketId)
          && _isEqual(prev.warehouseId, curr.warehouseId)
          && _isEqual(prev.userId, curr.userId)
      })
    ).subscribe((session: SocketSession) => {
      this.handleSocketSessionChange(session);
    });
  }

  /**
   * @description Listen events from socket
   * @template T class to object result
   * @param {string} event Name of event to subscribe
   * @return {Observable<T>} Subscription
   */
  public listen<T>(event: string): Observable<T> {
    return this.socket.fromEvent(event);
  }

  /**
   * @description Configurate user with socket instance to work
   * @param {SocketSession} session User session to add to room
   * @return {void}
   */
  public setSocketSession(session: SocketSession): void {
    this.socketSession.next({...session, socketId: this.socket.ioSocket.id});
  }

  /**
   * @description Sends an event to the websockets server indicating that the client is requesting to join a channel
   * @param {string} channelId, identifier of the channel the customer is requesting to join
   * @return {void}
   */
  public joinToChannel(channelId: string): void {
    // unconnected from web socket service, cannot join channels
    if (!this.socket.ioSocket.id) {
      return;
    }
    this.socket.emit(
      SocketEvents.JOIN_CHANNEL,
      this.getPayload({id: channelId})
    );
    this.subscribedChannels.add(channelId);
  }

  /**
   * @description unsubscribe from all registered channels
   * @returns {void}
   */
  private leaveAllChannels(): void {
    // unconnected from web socket service, no need to leaved channels or no channels subscribed
    if (!this.socket.ioSocket.id || _isEqual(this.subscribedChannels.size, CONSTANTS.ZERO)) {
      return;
    }
    this.socket.emit(SocketEvents.LEAVE_CHANNELS, this.getPayload([...this.subscribedChannels]));
    this.subscribedChannels.clear();
  }

  /**
   * @description Generates the payload necessary for communication with the web sockets service.
   * @param {T | undefined} body, additional information
   * @returns {SocketPayload<T>}
   */
  private getPayload<T>(body?: T): SocketPayload<T> {
    const tenantId = ENVIRONMENT.TENANT_ID || SocketService.DEFAULT_TENANT_ID;
    return {
      microServiceId: ENVIRONMENT.SOCKET_WEP_CORE_API_MICROSERVICE_ID || SocketService.DEFAULT_SOCKET_MICROSERVICE_ID,
      transactionId: _toString(Math.floor(Math.random() * Date.now())),
      clientId: this.currentClientId,
      body,
      tenantId
    };
  }

  /**
   * @description handles the change of socket session that occurs when changing warehouse, user or on reconnections with the socket service
   * @param {SocketSession} session 
   * @returns {void}
   */
  private handleSocketSessionChange(session: SocketSession): void {
    const userOrWarehouseChange = !_isEqual(session.userId, this.lastSocketSession.userId) || !_isEqual(session.warehouseId, this.lastSocketSession.warehouseId);
    const noChannelSubscriptionsYet = _isEqual(this.subscribedChannels.size, CONSTANTS.ZERO);
    this.currentClientId = _toString(session.userId);
    if (!_isNil(session.socketId)) {
      this.lastSocketSession = session;
    }
    // only join to default warehouse channel
    if (userOrWarehouseChange || noChannelSubscriptionsYet) {
      this.leaveAllChannels();
      this.joinToChannel(SocketChannels.WAREHOUSE + session.warehouseId);
      return;
    }
    // only socket id change this means that a reconexion occours
    for(const channelId of this.subscribedChannels.values()) {
      this.joinToChannel(channelId);
    }
  }
}
