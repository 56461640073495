import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Message } from 'primeng/api/message';
import { Observable, of, throwError } from 'rxjs';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import * as _ from 'lodash';

import { CONSTANTS } from '../shared/constants';
import { ENVIRONMENT } from '../../environments/environment';
import { SpecialKeyCode } from '../shared/constants/special-keyCode';
import { WepError } from '../shared/wep-error';

const XLSX = '.xlsx';
export class CalendarUtils {
  /**
   * @description Initializes the primeng calendar to spanish language
   * @return {any} spanishCalendar.
   */
  public static spanishInit(): any {
    let spanishCalendar: any;
    spanishCalendar = {
      firstDayOfWeek: 1,
      dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
      dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
      dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
      monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre',
        'noviembre', 'diciembre'],
      monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic']
    };
    return spanishCalendar;
  }
}

export class MessageUtils {
  public msgs: Message[] = [];
  /**
   * @description This method displays a message in the users screen
   * @return {Function}
   */
  public static displayMessageInit(): Function {
    let displayMessage = function (message: any, seconds: number = 4) {
      this.msgs = [];
      this.msgs.push({ severity: message.severity, summary: message.summary, detail: message.detail });
      setTimeout(function () { this.msgs.shift(); }.bind(this), seconds * 1000);
    };

    return displayMessage;
  }
}

export class SpecialCharactersValidator {
  /**
   * @description Validate if character will be part of special characters
   * @param {KeyboardEvent} keyEvent to validate
   * @return {boolean}
   */
  public static validateCharactersOnlyHyphen(keyEvent: KeyboardEvent): boolean {
    if (_.isEqual(keyEvent.code, SpecialKeyCode.MINUS) ||
      _.isEqual(keyEvent.code, SpecialKeyCode.BACKQUOTE) ||
      _.isEqual(keyEvent.code, SpecialKeyCode.BRACKET_LEFT) ||
      _.isEqual(keyEvent.code, SpecialKeyCode.BRACKET_RIGHT) ||
      _.isEqual(keyEvent.code, SpecialKeyCode.COMMA) ||
      _.isEqual(keyEvent.code, SpecialKeyCode.EQUAL) ||
      _.isEqual(keyEvent.code, SpecialKeyCode.INT_BACK_SLASH) ||
      _.isEqual(keyEvent.code, SpecialKeyCode.PERIOD) ||
      _.isEqual(keyEvent.code, SpecialKeyCode.QUOTE) ||
      _.isEqual(keyEvent.key, SpecialKeyCode.ADMIRATION_SIGN) ||
      _.isEqual(keyEvent.key, SpecialKeyCode.AMPERSON) ||
      _.isEqual(keyEvent.key, SpecialKeyCode.AT_SIGN) ||
      _.isEqual(keyEvent.key, SpecialKeyCode.CLOSE_PARENTHESIS) ||
      _.isEqual(keyEvent.key, SpecialKeyCode.DIAGONAL) ||
      _.isEqual(keyEvent.key, SpecialKeyCode.EQUAL_SIGN) ||
      _.isEqual(keyEvent.key, SpecialKeyCode.HASH_TAG) ||
      _.isEqual(keyEvent.key, SpecialKeyCode.MONEY) ||
      _.isEqual(keyEvent.key, SpecialKeyCode.OPEN_PARENTHESIS) ||
      _.isEqual(keyEvent.key, SpecialKeyCode.PERCENTAGE) ||
      _.isEqual(keyEvent.key, SpecialKeyCode.QUOTATION_MARKS) ||
      _.isEqual(keyEvent.key, SpecialKeyCode.SLASH_DOWN) ||
      _.isEqual(keyEvent.code, SpecialKeyCode.BACK_SLASH)) {
      return true;
    } else {
      return false;
    }
  }
}

/* Form utilities */
export class FormUtils {
  public static isValid(field: UntypedFormControl) {
    let valid: boolean;
    valid = field.valid && field.dirty;
    return valid;
  }

  /**
   * @description Validate every field of the form and get error message
   * to build an object of error's message by field
   * @param {FormGroup} form form to validate
   * @param {[key: string]: Object} data  Data's form
   * @param {any} errorMessages Object with validation messages to return if error exists
   * @return {[key: string]: string} Object with errror's messages matched by form field
   */
  public static validateForm(form: UntypedFormGroup, data: { [key: string]: Object }, errorMessages: any): { [key: string]: string } {
    if (!data) { return {}; }
    let formErrors: { [key: string]: string } = {};

    _.map(data, (value: any, field: string) => {
      // clear previous error message (if any)
      formErrors[field] = '';
      let control = form.get(field);

      if (control && control.dirty && !control.valid) {
        for (let key in control.errors) {
          if (errorMessages[key]) {
            formErrors[field] += errorMessages[key] + ' ';
          }
        }
      }
    });
    return formErrors;
  }

  /**
   * @description Validates if a given data contains only numbers
   * @param {number} value Number to evaluate
   * @return {boolean} True if is valid and false if not
   */
  public static validateOnlyNumbers(value: number | string): boolean {
    let numberValue: number;

    if (typeof value === 'string' && /^[0-9]+$/.test(value)) {
      numberValue = _.toInteger(value);
    } else if (typeof value === 'number') {
      numberValue = value;
    }

    return !_.isNaN(numberValue) && _.isFinite(numberValue) &&
      /^[0-9]+$/.test(numberValue.toString()) &&
      numberValue > 0 && Number.isInteger(numberValue);
  }

  /**
   * @description Validates if a given data not contains any space.
   * @param {string} value string to evaluate
   * @return {boolean} True if it doesn't have spaces
   */
  public static validateNoSpaces(value: string): boolean {
    return value.trim().length > 0;
  }
}

/* Array utilities */
export class ArrayUtils {
  /**
   * @description Sort a list of items by Capital Letter considering accented vowels
   * @param {Array<any>} list Number to evaluate
   * @param {any} attr If it is an array of objects, you can specify an attribute from each object to sort by
   * @return {Array<any>} Sorted list
   */
  public static sortByCapitalLetter(list: Array<any>, attr?: any): Array<any> {
    return _.sortBy(list, (item: any) => {
      let newItem: any = attr ? item[attr] : item;
      let vowels: Array<{ [key: string]: string }> = [
        { toFind: 'Á', toReplace: 'A' },
        { toFind: 'É', toReplace: 'E' },
        { toFind: 'Í', toReplace: 'I' },
        { toFind: 'Ó', toReplace: 'O' },
        { toFind: 'Ú', toReplace: 'U' }
      ];
      let found: any;
      _.map(vowels, (vowel: any) => {
        if (_.startsWith(newItem, vowel.toFind)) {
          found = vowel;
        }
      });
      return found ? newItem.replace(found.toFind, found.toReplace) : newItem;
    });
  }
}

export class ArrayUtilities {
  /**
   * @description Validate two objects, compare their properties and verify if they are identical or not.
   * @param {Arrar[]} array Array Object that contains the information to compare.
   * @return {boolean}
   */
  public static allEqualInArray(array: Array<any>): boolean {
    if (_.isEmpty(array)) {
      return false;
    }

    let allEqualResult = array.every(value => value === _.head(array));
    return allEqualResult;
  }
}

/* Snapshot utilities */

export class SnapshotUtils {

  /**
   * @description Save value object to localStorage
   * @param {string} view View / view title to save state from
   * @param {any} value Value object to save, can be anything (usually an object or array)
   */
  public static saveState(view: string, key: string, value: any) {
    let currentState: any[];
    let newState = true;
    let updateItem: any;

    currentState = this.getState(view);
    updateItem = _.find(currentState, { id: key });

    if (updateItem) {
      newState = false;
      updateItem.value = value;
    }

    if (newState) {
      let newItem = { id: key, value: value };
      currentState.push(newItem);
    }

    localStorage.setItem(view, JSON.stringify(currentState));
  }

  /**
   * @description Get element's saved object from current id and view
   * @param {string} view Current view's name to select element from
   * @param {string} key Object id to retrieve data from localStorage
   * @return {any} Saved object retrieved from localStorage
   */
  public static getSavedElement(view: string, key: string): any {
    let state: any[];
    let element: any;

    state = this.getState(view);
    if (!_.isEmpty(state)) {
      element = _.find(state, { id: key });
    }
    return element;
  }

  /**
   * @description Get current view's ui state from localStorage
   * @param {string} view View / view title to obtain state from
   * @return {Array<any>} Array of saved values from indicated view
   */
  public static getState(view: string): Array<any> {
    let state: any;
    state = JSON.parse(localStorage.getItem(view)) || [];
    return state;
  }

  /**
   * @description Clear current view's ui state from localStorage
   * @param {string} view View / view title to obtain state from
   */
  public static clearState(view: string): void {
    localStorage.removeItem(view);
  }

  /**
   * @description Deleted current element/component from localStorage
   * @param {string} view View / view title of the component that the function receipt
   * @param {string} key Screen / Screen to delete an element from the Array.
   * @return {void}
   */
  public static removeSavedElement(view: string, key: string): void {
    let currentState: any[];
    currentState = this.getState(view);
    _.remove(currentState, function (state) {
      return _.isEqual(key, state.id);
    });
    localStorage.setItem(view, JSON.stringify(currentState));
  }

  /**
   * @description Cleans view's path from route paramaters to avoid dynamic paths
   * @param {string} viewPath View's path
   * @return {string} Static view's path
   */
  public static cleanViewPath(viewPath: string): string {
    let pathArr: Array<string> = viewPath.split('/');
    return pathArr[0] + (pathArr[1] ? pathArr[1] : '');
  }
}

export class NumberUtils {
  /**
   * Rounds to the indicated decimals.
   * @param {number} value  - value to be rounded.
   * @param {number} decimals - decimals for the quantity.
   * @returns {number}
   */
  public static round(value: number, decimals: number): number {
    return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals);
  }

  /**
   * Add two decimal numbers.
   * @param {number | Number | string | String} firstNumber  - first number to add.
   * @param {number | Number | string | String} secondNumber - second number to add.
   * @param {number} decimalsLimit - Limit of decimals in result. Default 2
   * @returns {number} result of addition in decimal.
   */
  public static decimalAddition(
    firstNumber: number | Number | string | String,
    secondNumber: number | Number | string | String,
    decimalsLimit: number = CONSTANTS.TWO
  ): number {
    if (_.isEqual(typeof firstNumber, 'number') || firstNumber instanceof Number) {
      firstNumber = _.toString(firstNumber);
    }
    if (_.isEqual(typeof secondNumber, 'number') || secondNumber instanceof Number) {
      secondNumber = _.toString(secondNumber);
    }
    const forceDecimals = this.getMayorNumberOfDecimals(firstNumber, secondNumber);
    const firstNum = this.parseDecimalToInt(firstNumber, forceDecimals);
    const secondNum = this.parseDecimalToInt(secondNumber, forceDecimals);
    const filledDecimals = _.fill(Array(forceDecimals), _.toString(CONSTANTS.ZERO));
    const divisor = _.toNumber(`1${_.join(filledDecimals, CONSTANTS.EMPTY_STRING)}`);
    const sum = (firstNum + secondNum) / divisor;
    return this.truncateDecimals(sum, decimalsLimit);
  }

  /**
   * Subtract two decimal numbers.
   * @param {number | Number | string | String} minuend - first number to subtract.
   * @param {number | Number | string | String} subtrahend - second number to subtract.
   * @param {number} decimalsLimit - Limit of decimals in result. Default 2
   * @returns {number} result of substraction in decimal.
   */
  public static decimalSubtraction(
    minuend: number | Number | string | String,
    subtrahend: number | Number | string | String,
    decimalsLimit: number = CONSTANTS.TWO
  ): number {
    if (_.isEqual(typeof minuend, 'number') || minuend instanceof Number) {
      minuend = _.toString(minuend);
    }
    if (_.isEqual(typeof subtrahend, 'number') || subtrahend instanceof Number) {
      subtrahend = _.toString(subtrahend);
    }
    const forceDecimals = this.getMayorNumberOfDecimals(minuend, subtrahend);
    const minuendConverted = this.parseDecimalToInt(minuend, forceDecimals);
    const subtrahendConverted = this.parseDecimalToInt(subtrahend, forceDecimals);
    const filledDecimals = _.fill(Array(forceDecimals), _.toString(CONSTANTS.ZERO));
    const divisor = _.toNumber(`1${_.join(filledDecimals, CONSTANTS.EMPTY_STRING)}`);
    const subtract = (minuendConverted - subtrahendConverted) / divisor;
    return this.truncateDecimals(subtract, decimalsLimit);
  }

  /**
   * @description Multiply two values with decimal and returns the product
   * @param {number | Number | string | String} multiplier - The first number in a multiplication.
   * @param {number | Number | string | String} multiplicand - The second number in a multiplication.
   * @param {number} decimalsLimit - Limit of decimals in result. Default 2
   * @returns {number} - Returns the product.
   */
  public static decimalMultiplication(
    multiplier: number | Number | string | String,
    multiplicand: number | Number | string | String,
    decimalsLimit: number = CONSTANTS.TWO
  ): number {
    if (_.isEqual(typeof multiplier, 'number') || multiplier instanceof Number) {
      multiplier = _.toString(multiplier);
    }
    if (_.isEqual(typeof multiplicand, 'number') || multiplicand instanceof Number) {
      multiplicand = _.toString(multiplicand);
    }
    const multiplierConverted = this.parseDecimalToInt(<string>multiplier);
    const multiplicandConverted = this.parseDecimalToInt(<string>multiplicand);
    const divisor = this.getDecimalDivisor(multiplier, multiplicand);
    const multiplicationRes = (multiplierConverted * multiplicandConverted) / divisor;
    return this.truncateDecimals(multiplicationRes, decimalsLimit);
  }

  /**
   * @description Sum all values from list
   * @param {any[]} list list to get values
   * @param {string} field Value to get and sum
   * @return {number} Summed value
   */
  public static sumBy(list: any[], field: string): number {
    let total = CONSTANTS.ZERO;
    _.forEach(list, (item) => {
      total = this.decimalAddition(total, item[field]);
    });
    return total;
  }

  /**
   * @description Truncate (truncate, no round) decimals of a product
   * @param {number | Number | string | String} originNumber - Number to evalute
   * @param {number} limit - Limit of decimals to show
   * @example
   * originNumber = 1.899 and limit = 2 returns 1.89
   * @returns {number} - Number with decimals truncated
   */
  public static truncateDecimals(originNumber: number | Number | string | String, limit: number = CONSTANTS.TWO): number {
    if (_.isEqual(typeof originNumber, 'number') || originNumber instanceof Number) {
      originNumber = _.toString(originNumber);
    }
    const originNumberSplited = _.split(originNumber, CONSTANTS.DOT);
    const absNumber = (originNumberSplited && originNumberSplited[CONSTANTS.ZERO])
      ? originNumberSplited[CONSTANTS.ZERO] : CONSTANTS.EMPTY_STRING;
    const decimals = (originNumberSplited && originNumberSplited[CONSTANTS.ONE])
      ? originNumberSplited[CONSTANTS.ONE] : CONSTANTS.EMPTY_STRING;
    const outDecimals = decimals.substring(CONSTANTS.ZERO, limit);
    const outNumber = absNumber + CONSTANTS.DOT + outDecimals;
    return _.toNumber(outNumber);
  }

  /**
   * @description Converts a decimal number to int number, base in decimals of origin number
   * @param {number | Number | string | String} originNumber - Number to convert.
   * @param {number} forceDecimals - Number of decimals to force
   * @returns {number} - Number as int
   */
  private static parseDecimalToInt(originNumber: number | Number | string | String,
    forceDecimals?: number): number {
    if (_.isEqual(typeof originNumber, 'number') || originNumber instanceof Number) {
      originNumber = _.toString(originNumber);
    }
    const originNumberSplited = _.split(originNumber, CONSTANTS.DOT);
    let decimals = (originNumberSplited && originNumberSplited[CONSTANTS.ONE])
      ? Array.from(originNumberSplited[CONSTANTS.ONE]) : [];
    if (!_.isEqual((_.size(decimals) % CONSTANTS.TWO), CONSTANTS.ZERO)) {
      decimals.push(_.toString(CONSTANTS.ZERO));
    }
    if (forceDecimals && !_.isEqual(_.size(decimals), forceDecimals)) {
      const decimalsDifference = _.subtract(forceDecimals, _.size(decimals));
      decimals = _.concat(decimals, Array(decimalsDifference));
    }
    const filledDecimals = _.fill(Array.from(decimals), _.toString(CONSTANTS.ZERO));
    const multiplicand = `1${_.join(filledDecimals, CONSTANTS.EMPTY_STRING)}`;
    const multiplicationRes = _.multiply(_.toNumber(originNumber), _.toNumber(multiplicand));
    return _.toNumber(multiplicationRes.toFixed(_.size(filledDecimals)));
  }

  /**
   * @description Gets decimal divisor evaluating numbers
   * @param {number | Number | string | String} firstNumber - The first number to evaluate.
   * @param {number | Number | string | String} secondNumber - The second number to evaluate.
   * @returns {number} - Returns the divisor.
   */
  private static getDecimalDivisor(
    firstNumber: number | Number | string | String,
    secondNumber: number | Number | string | String
  ): number {
    if (_.isEqual(typeof firstNumber, 'number') || firstNumber instanceof Number) {
      firstNumber = _.toString(firstNumber);
    }
    if (_.isEqual(typeof secondNumber, 'number') || secondNumber instanceof Number) {
      secondNumber = _.toString(secondNumber);
    }
    const firstNumberSplited = _.split(firstNumber, CONSTANTS.DOT);
    const secondNumberSplited = _.split(secondNumber, CONSTANTS.DOT);
    const firstNumberDecimals = (firstNumberSplited && firstNumberSplited[CONSTANTS.ONE])
      ? Array.from(firstNumberSplited[CONSTANTS.ONE]) : [];
    const secondNumberDecimals = (secondNumberSplited && secondNumberSplited[CONSTANTS.ONE])
      ? Array.from(secondNumberSplited[CONSTANTS.ONE]) : [];
    if (!_.isEqual((_.size(firstNumberDecimals) % CONSTANTS.TWO), CONSTANTS.ZERO)) {
      firstNumberDecimals.push(_.toString(CONSTANTS.ZERO));
    }
    if (!_.isEqual((_.size(secondNumberDecimals) % CONSTANTS.TWO), CONSTANTS.ZERO)) {
      secondNumberDecimals.push(_.toString(CONSTANTS.ZERO));
    }
    const decimals = _.concat(firstNumberDecimals, secondNumberDecimals);
    const filledDecimals = _.fill(decimals, _.toString(CONSTANTS.ZERO));
    const divisor = `1${_.join(filledDecimals, CONSTANTS.EMPTY_STRING)}`;
    return _.toNumber(divisor);
  }

  /**
   * @description Gets the mayor number of decimals of two numbers
   * @param {number | Number | string | String} firstNumber  - first number to add.
   * @param {number | Number | string | String} secondNumber - second number to add.
   * @example
   * firstNumber = 1.5432
   * secondNumber = 1.55
   * returns 4
   * @returns {number} - Greather number of decimals
   */
  private static getMayorNumberOfDecimals(
    firstNumber: number | Number | string | String,
    secondNumber: number | Number | string | String
  ): number {
    if (_.isEqual(typeof firstNumber, 'number') || firstNumber instanceof Number) {
      firstNumber = _.toString(firstNumber);
    }
    if (_.isEqual(typeof secondNumber, 'number') || secondNumber instanceof Number) {
      secondNumber = _.toString(secondNumber);
    }
    const firstNumSplited = _.split(<string>firstNumber, CONSTANTS.DOT);
    const secondNumSplited = _.split(<string>secondNumber, CONSTANTS.DOT);
    const firsNumDecimals = (firstNumSplited.length) ? Array.from(firstNumSplited[CONSTANTS.ZERO]) : Array(CONSTANTS.TWO);
    const secondNumDecimals = (secondNumSplited.length) ? Array.from(secondNumSplited[CONSTANTS.ZERO]) : Array(CONSTANTS.TWO);
    if (!_.isEqual((_.size(firsNumDecimals) % CONSTANTS.TWO), CONSTANTS.ZERO)) {
      firsNumDecimals.push(_.toString(CONSTANTS.ZERO));
    }
    if (!_.isEqual((_.size(secondNumDecimals) % CONSTANTS.TWO), CONSTANTS.ZERO)) {
      secondNumDecimals.push(_.toString(CONSTANTS.ZERO));
    }
    const firsNumDecimalsSize = _.size(firsNumDecimals);
    const secondNumDecimalsSize = _.size(secondNumDecimals);
    if (_.isEqual(firsNumDecimalsSize, secondNumDecimalsSize) || firsNumDecimalsSize > secondNumDecimalsSize) {
      return firsNumDecimalsSize;
    } else if (firsNumDecimalsSize < secondNumDecimalsSize) {
      return secondNumDecimalsSize;
    }
  }

  /**
   * Validate if quantity will be rounded with 0.01 or truncate with decimals
   * @param {number} quantity - value to be validated.
   * @returns {number}
   */
  public static validateIfTruncateDecimals(quantity: number): number {
    let quantityConverted: number;

    if (quantity < _.toNumber(CONSTANTS.ZERO_DEFAULT_VOLUME)) {
      let newQuantity: string = CONSTANTS.ZERO_DEFAULT_VOLUME;
      quantityConverted = parseFloat(newQuantity);
    } else {
      quantityConverted = this.truncateDecimals(quantity);
    }
    return quantityConverted;
  }
}

/* Handle Error */
export class HandleError {

  /**
   * @description Handle errorss
   * @param {Response} error handled Error
   * @param {any} error handled Error
   * @return Error message
   */
  public static handleErrorPromise(error: Response | any) {
    let errWep: WepError = new WepError();
    if (error.status === 0) {
      errWep.message = ENVIRONMENT.OFFLINE_ERROR;
    } else {
      let errorWep = error;
      errWep.message = errorWep.message;
    }
    console.error(`${errWep.message}`);
    return throwError(errWep);
  }

  /**
   * @description Handle errors
   * @param {Response} error handled Error
   * @param {any} error handled Error
   * @return Error message
   */
  public static handleErrorObservable(error: any) {
    let errWep: WepError = new WepError();
    if (error.status === 0) {
      errWep.message = ENVIRONMENT.OFFLINE_ERROR;
    } else {
      let errorWep = error;
      errWep.message = errorWep.error.message;
      errWep.data = errorWep.error.data;
    }
    return throwError(errWep);
  }

  /**
   * @description Handle errors
   * @param {any} error received error
   * @param {any} item item to return (optional)
   * @return {WepError | HandledErrorInterface} handled error
   */
  public static handleError(error: any, item?: any): Observable<HandledErrorInterface | WepError> {
    let errWep: WepError = new WepError();
    if (error.status === 0) {
      errWep.message = ENVIRONMENT.OFFLINE_ERROR;
    } else {
      let errorWep = error.json();
      errWep.message = errorWep.message;
    }

    if (item !== undefined) {
      let handledError: HandledErrorInterface = {
        error: error,
        data: item
      };
      return of(handledError);
    } else {
      return throwError(errWep);
    }
  }
}

/* Export to Excel */
export class ExportToExcel {
  /**
   * @description Download blob file and open save as window
   * @param {any} exportedFile Exported file
   * @param {string} filename file's name
   */
  public static saveAs(exportedFile: any, filename: string) {
    saveAs(exportedFile.body, filename + XLSX);
  }

  /**
   * @description generates a csv given the data, filename and <a> link reference
   * @param {any} data data representing the csv
   * @param {string} filename name of the file to download
   * @param {HTMLElement} reference a dom reference of an <a> tag to emulate the click action
   */
  public static generateCSV(data: any, filename: string, reference: HTMLElement) {
    let csvContent = 'data:text/csv;charset=utf-8,' + CONSTANTS.BYTE_ORDER_MARK + encodeURI(data);
    reference.setAttribute('href', csvContent);
    reference.setAttribute('download', filename);
    reference.click();
  }
}

export class FileUtils {
  /**
   * @description builds a file from a given buffer
   * @param {Buffer} oldbuffer binary buffer to convert
   * @param {string} filename name of the file to build
   * @return {File} built pdf file
   */
  public static buildPdfFromBuffer(oldbuffer: any, filename: string): File {
    let arrayBuffer = new ArrayBuffer(oldbuffer.length);
    let view = new Uint8Array(arrayBuffer);
    for (let arrayBufferIndex = 0; arrayBufferIndex < oldbuffer.length; ++arrayBufferIndex) {
      view[arrayBufferIndex] = oldbuffer[arrayBufferIndex];
    }
    let file = new File([new Blob([arrayBuffer], { type: 'application/pdf' })], filename);
    return file;
  }
}

export class DateUtil {
  /**
   * @description Receives start and end date, and returns difference of those dates in days
   * @param {string} startDate - Start date
   * @param {string} endDate - End date to calculate difference
   * @return {number} - Difference between dates in days
   */
  public static getDateDifferenceInDays(startDate: string, endDate: string): number {
    return moment(endDate).diff(startDate, 'days');
  }

  /**
   * @description Returns the current Date
   * @param {Date} dateToParse - Date to parse
   * @return {Date} - Current date in the database format
   */
  public static getThisMoment(dateToParse: Date = new Date()): Date {
    return moment.utc(dateToParse).toDate();
  }
}

interface HandledErrorInterface {
  error: WepError;
  data: any;
}
